
class UtilService {
  get_formatted_date(params: any, type: string, format: boolean, sign: any) {
    if (type === 'day') {
      let date = null;
      if (format == true) {
        if ((new Date(params).getDate()) < 10) {
          date = "0" + new Date(params).getDate();
        } else {
          date = new Date(params).getDate();
        }
      } else {
        date = new Date(params).getDate();
      }

      return date;
    }
    else if (type === 'month') {
      let month = null;
      if (format == true) {
        if ((new Date(params).getMonth() + 1) < 10) {
          month = new Date(params).getMonth() + 1;
          month = "0" + month.toString()
        } else {
          month = new Date(params).getMonth() + 1;
        }
      }
      else {
        month = new Date(params).getMonth() + 1;
      }
      return month;

    }
    else if (type === 'year') {
      let year = new Date(params).getFullYear();
      return year;
    } else {
      let month = null
      let date = null;
      let year = new Date(params).getFullYear();

      if ((new Date(params).getMonth() + 1) < 10) {
        month = new Date(params).getMonth() + 1;
        month = "0" + month.toString()
      }
      if ((new Date(params).getDate()) < 10) {
        date = "0" + new Date(params).getDate();
      }
      console.log(year)
      console.log(month)
      console.log(date)
      let data = year?.toString() + sign + date?.toString() + sign + month?.toString()
      console.log(data)
      return data;
    }
  }
  checkIfKeyIsEmpty(obj: any, keyArray: string[]): boolean {
    return keyArray.some(key => obj?.[key] === null || obj?.[key] === '' || obj?.[key] === undefined);
  }
  getEmptyKeys(obj: any, keyArray: string[]): string[] {
    return keyArray.filter(key => obj?.[key] === null || obj?.[key] === '' || obj?.[key] === undefined);
  }
  validateArray(array: any[], keyArray: string[]): string[] {
    const emptyKeys = array.reduce((result, item, index) => {
      keyArray.forEach(key => {
        if (!item[key]) {
          result.push(`${key}`);
        }
      });
      return result;
    }, []);

    return emptyKeys;
  }
  generateErrorMessage(attributes: string[]) {
    if (!attributes || attributes.length === 0) {
      return true;
    }

    const formattedAttributes = attributes.map(attribute => attribute.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()));
    const errorMessage = `Please provide the following information: ${formattedAttributes.join(', ')}.`;

    return errorMessage;
  }
  generateErrorMessageFields(attributes: string[], keyNameMapping: Record<string, string>) {
    if (!attributes || attributes.length === 0) {
      return true;
    }

    const formattedAttributes = attributes.map(attribute => keyNameMapping[attribute] || attribute)
      .map(attribute => attribute.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()));
    const errorMessage = `Please provide the following information: ${formattedAttributes.join(', ')}.`;

    return errorMessage;
  }
  //for payroll configuration
  getInvalidFields(obj: any): string[] {
    const invalidFields = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const field = obj[key];
        if (field.status === true && (field.name === null || field.name.trim() === '')) {
          invalidFields.push(key);
        }
      }
    }

    return invalidFields;
  }
  //for payroll configuration
  generatePayrollConfigMessage(attributes: string[]) {
    if (!attributes || attributes.length === 0) {
      return true;
    }

    const invalidFields = attributes.map(attribute => attribute.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()));
    const errorMessage = `The following fields have status active but are empty: ${invalidFields.join(', ')}.`;

    return errorMessage;
  }
  calculateTotalExcludingVAT(qty: number, unitRate: number): number {
    // return qty * unitRate;
    const totalExcludingVAT = qty * unitRate;
    return Number(totalExcludingVAT.toFixed(2));
  }
  calculateTaxAmount(totalExcludingVAT: number, taxStructure: number): number {
    // return totalExcludingVAT * (taxStructure / 100);
    const taxAmount = totalExcludingVAT * (taxStructure / 100);
    return Number(taxAmount.toFixed(2));
  }

  calculateTotalAmountWithTax(totalExcludingVAT: number, taxAmount: number): number {
    const totalAmount = totalExcludingVAT + taxAmount;
    return Number(totalAmount.toFixed(2));
    // return totalExcludingVAT - taxAmount;
  }
  calculateNetAmount(totalExcludingVAT: number, discount:number): number{
    // return totalExcludingVAT - discount
    const netAmount = totalExcludingVAT - discount;
    return Number(netAmount.toFixed(2));
  }
}



export default new UtilService();



