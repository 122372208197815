
import { Component, Prop, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import RSidebar from "@/components/right-sidebar.vue"; // @ is an alias to /src
import projectService from "../../../services/project-service";
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp,
  },
})
export default class Employee extends Vue {
  @Prop({ type: String })
  id!: string;
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 100;
  private currentPage = 1;
  public create_obj: any = {};
  saveData = true;

  public sub_projects_select = null;

  public fields = [
    {
      key: "title",
      sortable: false,
    },
    {
      key: "owner",
      sortable: false,
    },
    {
      key: "branch",
      sortable: false,
    },
    {
      key: "description",
      sortable: false,
    },
    {
      key: "sub_branch",
      sortable: false,
    },
    {
      key: "parend_id",
      sortable: false,
    },
    {
      key: "supervisor",
      sortable: false,
    },
    {
      key: "amount",
      sortable: false,
    },
    {
      key: "start_date",
      sortable: false,
    },
    {
      key: "end_date",
      sortable: false,
    },
    {
      key: "retention_per",
      sortable: false,
    },
    {
      key: "retention_date",
      sortable: false,
    },
    {
      key: "comments",
      sortable: false,
    },
  ];

  public main_obj: any = {};
  public sub_projects: any = {};
  public revenue: any = [];
  public retention: any = [];
  public customer: any = [];
  public cost_centers: any = {};
  public type: string = "";
  errorProject: any = "";
  errorNode: any = "";
  errorMaster: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end

  handleCreate(obj: object, type: string) {
    this.create_obj["user"] = 2;
    this.errorNode = "";
    this.errorMaster = "";
    const fieldKeyMapping = {
      code: "Code",
      title: "Title",
      amount: "Amount",
      start_date: "Start Date",
      end_date: "End Date",
      retention_per: "Retention Per",
      retention_days: "Retention Days",
      comments: "Comments",
      owner: "Supervisor",
      parent_id: "Sub projects",
      customer_account: "Customer Account",
      retention_account: "Retention Account",
      revenue_account: "Revenue Account",
    };
    const keyMap = fieldKeyMapping;
    const commonFields = ['code', 'title', 'amount', 'start_date', 'end_date', 'retention_per', 'retention_days', 'comments'];
    if (type == "node") {
      commonFields.push('owner');
      this.create_obj["node_type"] = "Node";
      this.create_obj["parent_id"] = this.id;

    } else if (type == "master") {
      this.create_obj["node_type"] = "Master";
      commonFields.push('parent_id', 'customer_account', 'retention_account', 'revenue_account');
    }
    const error_list = utils.getEmptyKeys(this.create_obj, commonFields);
    if (error_list.length > 0) {
      if (type === "node") {
        this.errorNode = utils.generateErrorMessageFields(error_list, keyMap);
      } else if (type === "master") {
        this.errorMaster = utils.generateErrorMessageFields(error_list, keyMap);
      }
    } else {
      projectService
        .create_list(this.create_obj)
        .then((response) => {
          this.create_obj = {};
          // alert("Successfully Created");
          this.showModal("Created Successfully", [], "success")
          this.retrieve();
          this.get_childs(this.id);
          this.get_cost_centers();
        })
        .catch((e) => {
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  handleEdit(data: any, type: string) {
    this.saveData = false;
    this.errorNode = "";
    this.errorMaster = "";
    const commonFields = ['code', 'title', 'amount', 'start_date', 'end_date', 'retention_per', 'retention_days', 'comments'];

    if (type == "node") {
      this.create_obj["node_type"] = "Node";
      commonFields.push('owner');
    }
    else if (type == "master") {
      this.create_obj["node_type"] = "Master";
      commonFields.push('parent_id', 'customer_account', 'retention_account', 'revenue_account');
    }
    const error_list = utils.getEmptyKeys(this.create_obj, commonFields);
    if (error_list.length > 0) {
      if (type === "node") {
        this.errorNode = utils.generateErrorMessage(error_list);
      } else if (type === "master") {
        this.errorMaster = utils.generateErrorMessage(error_list);
      }
    } else {
      projectService
        .update_list(this.create_obj.id, this.create_obj)
        .then((response) => {
          this.create_obj = {};
          // alert("Successfully Updated");
          this.showModal("Updated Successfully", [], "success");
          this.retrieve();
          this.get_childs(this.id);
          this.get_cost_centers();
        })
        .catch((e) => {
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  resetCreateNode() {
    this.saveData = true;
    this.create_obj = {};
    this.errorNode = "";
  }
  resetCreateMaster() {
    this.saveData = true;
    this.create_obj = {};
    this.errorMaster = "";
  }

  edit_SubProjects(data: any, type: string) {
    this.saveData = false;
    if (type == "editNode") {
      this.create_obj = data;
      this.create_obj.id = data["id"];
      this.create_obj.title = data["title"];
      this.create_obj.user = data["user_id"]
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-node");
    }
  }

  edit_ProjectMaster(data: any, type: string) {
    this.saveData = false;
    if (type == "editMaster") {
      this.create_obj = data;
      this.create_obj.id = data["id"];
      this.create_obj.title = data["title"];
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-master");
    }

  }
  edit_parent(data: any) {
    this.errorProject = ""
    const error_list = utils.getEmptyKeys(this.main_obj, ['code', 'title', 'owner', 'amount', 'start_date', 'end_date', 'retention_per', 'retention_days'])
    if (error_list.length > 0) {
      this.errorProject = utils.generateErrorMessage(error_list)
    }
    else {
      this.main_obj = data;
      this.main_obj.id = data["id"];
      this.main_obj.title = data["title"];
      projectService.update_list(this.main_obj.id, this.main_obj)
        .then((response) => {
          this.main_obj = {};
          // alert("Successfully Updated");
          this.showModal("Updated Successfully", [], "success");
          this.retrieve();
        })
        .catch((e) => {
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }

  get_childs(id: string) {
    projectService
      .getChilds(id)
      .then((response) => {
        this.sub_projects = response.data.result;
        // console.log(this.sub_projects);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieve() {
    projectService
      .get_list_by_id(this.id)
      .then((response) => {
        this.main_obj = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  get_all_accounts(title: string, search: string, type: string) {
    projectService.getAllAccount(title, search).then((response) => {
      if (type === "retention") {
        this.retention = response.data.list;
      } else if (type === "revenue") {
        this.revenue = response.data.list;
      } else if (type === "customer") {
        this.customer = response.data.list;
      }
    });
  }
  get_cost_centers() {
    projectService
      .getAllCostCenters(this.id)
      .then((response) => {
        this.cost_centers = response.data.result;
        // console.log(this.cost_centers);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  mounted() {
    this.retrieve();
    this.get_childs(this.id);
    this.get_cost_centers();
  }
  resetProject() {
    this.main_obj = {};
    this.errorProject = "";
  }
}
