// import axios from "../common-axios";
import axios from "axios";

class ProjectService {

  // project-item
  get_list() { return axios.get("/project/list/"); }

  get_list_by_id(id: any) { return axios.get(`/project/list/${id}/`); }

  create_list(data: any) { return axios.post("/project/list/", data); }

  update_list(id: number, data: any) { return axios.put(`/project/list/${id}/`, data); }

  getByTitle(title: any) {
    return axios.get(`/project/get-project/?title=${title}`);
  }

  create_project_emp(data: any) {
    return axios.post(`/project/employee-project/`, data);
  }

  update_project_emp(id: number, data: any) {
    return axios.put(`/project/employee-project/${id}/`, data);
  }


  getAllprojectorder() {
    return axios.get("/project/order/");
  }

  getAllprojectorderlist() {
    return axios.get("/project/orderforce/");
  }

  getParent() {
    return axios.get('/project/get-parents/')
  }
  getChilds(id: string) {
    return axios.get(`/project/get-all-childs-of-project/?parent_id=${id}`)
  }

  getAllAccount(title: string, search: string) {
    return axios.get(`/project/get-customer-accounts/?title=${title}&search=${search}`)
  }

  getAllCostCenters(id: string) {
    return axios.get(`/project/get-cost-centers/?id=${id}`)
  }
  getAllCostCentersMasters(param:string){
    return axios.get(`/project/get-all-cost-centers/?title=${param}`)
  }
  getProjectsHierarchy(){
    return axios.get('/project/get-hirearchy-projects/')
  }

  importBulk(file:any){
    let formData = new FormData();
    formData.append('file', file);
    return axios.post('/project/import-bulk-costs-centers/',formData)
  }
  getCurrentProject(title:any){
    return axios.get(`/project/get-current-project/?title=${title}`);
  }
  // catgories
  // get_category(id: string) {
  //   if(id == ''){return axios.get(`/product/category/`);}
  //   else{return axios.get(`/product/category/${id}`); }
  // }
  // create_category(data: any) {return axios.post("/product/category/", data);}
  // update_category(id: string, data: any) {return axios.put(`/product/category/${id}/`, data);}
  // delete_category(id: string) {return axios.delete(`/product/category/${id}`);}
  // category_by_title(title: string) {return axios.get(`/product/category/?title=${title}`);}

  // catgories_level_1
  // get_sub_level_1_category(id: string) {
  //   if(id == ''){return axios.get(`/product/category-level-1/`);}
  //   else{return axios.get(`/product/category-level-1/${id}`); }
  // }
  // create_sub_level_1_category(data: any) {return axios.post("/product/category-level-1/", data);}
  // update_sub_level_1_category(id: string, data: any) {return axios.put(`/product/category-level-1/${id}/`, data);}
  // delete_sub_level_1_category(id: string) {return axios.delete(`/product/category-level-1/${id}`);}
  // sub_level_1_category_title(title: string) {return axios.get(`/product/category-level-1/?title=${title}`);}

  // catgories_level_2
  // get_sub_level_2_category(id: string) {
  //   if(id == ''){return axios.get(`/product/category-level-2/`);}
  //   else{return axios.get(`/product/category-level-2/${id}`); }
  // }
  // create_sub_level_2_category(data: any) {return axios.post("/product/category-level-2/", data);}
  // update_sub_level_2_category(id: string, data: any) {return axios.put(`/product/category-level-2/${id}/`, data);}
  // delete_sub_level_2_category(id: string) {return axios.delete(`/product/category-level-2/${id}`);}
  // sub_level_2_category_title(title: string) {return axios.get(`/product/category-level-2/?title=${title}`);}

}

export default new ProjectService();